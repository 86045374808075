.inicio-portafolio {
  width: 100%;
  height: auto;
  color: white;
}

.inicio-portafolio .imagen-background {
  width: 100%;
  height: 40vh;
  background-color: #1a1a1a;
  position: relative;
}

.inicio-portafolio .imagen-background .slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(26, 26, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;
}

.inicio-portafolio .imagen-background .slider-typography .slider-main {
  width: 75%;
  height: auto;
  margin: auto;
  text-align: center;
}

.inicio-portafolio .imagen-background .slider-typography .slider-main h1 {
  font-size: 32px;
  text-transform: uppercase;
  color: white;
}

.inicio-portafolio .imagen-background .slider-typography .slider-main p a {
  color: #626262;
  font-weight: bolder;
}

.inicio-portafolio
  .imagen-background
  .slider-typography
  .slider-main
  p
  a:hover {
  text-decoration: underline;
}

.portafolio {
  width: 100%;
  height: auto;
}

.portafolio .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
  text-align: center;
}

.portafolio .container .list-proyects {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.portafolio .container .list-proyects .proyect {
  width: 33.33%;
  height: auto;
}

.portafolio .container .list-proyects .proyect .cover {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 15px;
}

.portafolio .container .list-proyects .proyect .cover video {
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  object-position: center;
  transition: all 0.4s ease;
  border: 1px solid #f9f9f9;
}

.portafolio .container .list-proyects .proyect:hover .cover video {
  transform: scale(1.3);
}

.portafolio .container .list-proyects .proyect .info {
  width: 100%;
  height: auto;
  margin: 15px 0px;
}

.portafolio .container .list-proyects .proyect .info .date {
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: #626262;
  text-transform: uppercase;
}

.portafolio .container .list-proyects .proyect .info a {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin: 10px 0px;
}

.portafolio .container .list-proyects .proyect .info .button{
  text-align: left;
  background-color: transparent;
  border: 2px solid #1a1a1a;
  color: #1a1a1a;
  padding: 5px 15px;
  border-radius: 50px;
  width: max-content;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 10px;
  margin-top: 15px;
}

.portafolio .container .list-proyects .proyect .info .button:hover{
  color: white;
  background-color: #1a1a1a;
  border: 2px solid #1a1a1a;
}

.portafolio .container .list-proyects .proyect .info a:hover {
  color: #626262;
}

.portafolio .container .list-proyects .proyect .info p {
  font-size: 12px;
  margin: 0px;
  text-align: justify;
}

@media screen and (max-width: 599px) {
  .inicio-portafolio {
    width: 100%;
    height: auto;
    color: white;
  }
  
  .inicio-portafolio .imagen-background {
    width: 100%;
    height: 45vh;
    background-color: #1a1a1a;
    position: relative;
  }
  
  .inicio-portafolio .imagen-background .slider-typography {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(26, 26, 26, 0.6);
  }
  
  .inicio-portafolio .imagen-background .slider-typography .slider-main {
    width: 90%;
    height: auto;
    margin: 15vh auto;
    text-align: center;
  }
  
  .inicio-portafolio .imagen-background .slider-typography .slider-main h1 {
    font-size: 32px;
    text-transform: uppercase;
  }
  
  .inicio-portafolio .imagen-background .slider-typography .slider-main p a {
    color: #626262;
    font-weight: bolder;
  }
  
  .inicio-portafolio
    .imagen-background
    .slider-typography
    .slider-main
    p
    a:hover {
    text-decoration: underline;
  }
  
  .portafolio {
    width: 100%;
    height: auto;
  }
 
  .portafolio .container .list-proyects {
    flex-wrap: wrap;
  }
  
  .portafolio .container .list-proyects .proyect {
    width: 100%;
    height: auto;
  }
}
