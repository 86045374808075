@import url('https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: 'Mali', cursive;
  /*user-select: none;*/
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f9f9f9;
}

h1 {
  font-size: 32px;
  color: #1a1a1a;
  text-transform: uppercase;
}

h2 {
  font-size: 32px;
  color: #1a1a1a;
  text-transform: uppercase;
}

h3 {
  font-size: 20px;
  color: #1a1a1a;
  text-transform: uppercase;
}

p {
  color: #626262;
  font-size: 14px;
  margin: 15px 0px;
  font-weight: 500;
}


@media screen and (max-width: 599px) {
  body {
    overflow-x: hidden !important;
  }
}
