.inicio-articulo {
  width: 100%;
  height: auto;
  color: white;
}

.inicio-articulo .imagen-background {
  width: 100%;
  height: 40vh;
  background-color: #1a1a1a;
  position: relative;
}

.inicio-articulo .imagen-background .slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(26, 26, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;
}

.inicio-articulo .imagen-background .slider-typography .slider-main {
  width: 75%;
  height: auto;
  margin: auto;
  text-align: center;
}

.inicio-articulo .imagen-background .slider-typography .slider-main h1 {
  font-size: 32px;
  text-transform: uppercase;
  color: white;
}

.inicio-articulo .imagen-background .slider-typography .slider-main p a {
  color: #626262;
  font-weight: bolder;
}

.inicio-articulo
  .imagen-background
  .slider-typography
  .slider-main
  p
  a:hover {
  text-decoration: underline;
}

.articulo {
  width: 100%;
  height: auto;
  color: white;
}

.articulo .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
}

.articulo .container .firm {
  text-transform: uppercase;
  font-size: 12px;
}

.articulo .container p {
  margin: 10px 0px;
  font-weight: 500;
}

.articulo .container p a {
  color: #4fb286;
}

.articulo .container p a:hover {
  text-decoration: underline;
}

.articulo .container video {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.articulo {
  width: 100%;
  height: auto;
  color: white;
}

.articulo .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
}

.articulo .container .firm {
  font-size: 12px;
  text-transform: uppercase;
}


.articulo .container h2 {
  font-size: 20px;
}

.articulo .container h3 {
  font-size: 16px;
}

.articulo .container p span {
  font-weight: bolder;
  color: #1a1a1a;
}

.articulo .container p a {
  color: #4fb286;
}

.articulo .container p a:hover {
  text-decoration: underline;
}

.articulo .container p iframe {
  width: 1220px;
  height: 685px;
  border: 0;
}

.articulo .container p img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

pre {
  display: block;
  font-family: monospace !important;
  white-space: pre;
  margin: 15px 0px;
  user-select: text !important;
}

code,
pre {
  background: #1a1a1a;
  border-radius: 5px;
  font-family: monospace !important;
  user-select: text !important;
}

::selection {
  background: gray;
}

pre {
  padding: 10px;
  overflow: auto;
  box-shadow: 0 0 3px #1a1a1a;
  font-family: monospace !important;
}

code,
pre {
  font-family: monospace !important;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  font-size: 14px;
  tab-size: 4;
  hyphens: none;
  color: white !important;
}

code span:nth-child(1),
code span:nth-child(4) {
  font-family: monospace !important;
  color: yellow !important;
}

code span:nth-child(2) {
  font-family: monospace !important;
  color: #999;
}

code a {
  font-family: monospace !important;
  color: white;
}

code a:hover {
  text-decoration: underline;
}

.articulo .container ul {
  width: 100%;
  margin: 0px 0px 15px 45px;
  padding-right: 5%;
}

.articulo .container ul li {
  color: #626262;
  font-size: 14px;
  margin: 5px 0px;
  font-weight: 500;
  list-style: circle;
}

.articulo .container ul li strong {
  font-weight: bolder;
}

.articulo .container ol {
  width: 100%;
  margin: 0px 0px 15px 45px;
  padding-right: 5%;
}

.articulo .container ol li {
  color: #626262;
  font-size: 14px;
  margin: 5px 0px;
  font-weight: 500;
  list-style: decimal;
}

.articulo .container ol li strong {
  font-weight: bolder;
}

.articulo .container .tabla {
  display: block;
  white-space: nowrap;
  overflow: auto hidden;
  max-width: 100%;
  margin-top: 30px;
  border-bottom: 1px solid #999;
}

.articulo .container .tabla table {
  font-variant-numeric: lining-nums tabular-nums;
  border-collapse: collapse;
  width: 100%;
}

.articulo .container .tabla table thead tr th {
  padding: 12px 24px;
  font-size: 16px;
  color: #999;
  text-align: left;
  text-transform: uppercase;
}

.articulo .container .tabla table tbody tr td {
  padding: 16px 24px;
  font-size: 16px;
  color: white;
  text-align: left;
}

.tabla table tbody tr:nth-child(odd) > td,
.tabla table tbody tr:nth-child(odd) > th {
  background-color: #333;
}

.tabla table tbody tr:nth-child(even) > td,
.tabla table tbody tr:nth-child(even) > th {
  background-color: #1a1a1a;
}

@media screen and (max-width: 599px) {
  .inicio-articulo {
    width: 100%;
    height: auto;
    color: white;
  }
  
  .inicio-articulo .imagen-background {
    width: 100%;
    height: 45vh;
    background-color: #1a1a1a;
    position: relative;
  }
  
  .inicio-articulo .imagen-background .slider-typography {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(26, 26, 26, 0.6);
  }
  
  .inicio-articulo .imagen-background .slider-typography .slider-main {
    width: 90%;
    height: auto;
    margin: 15vh auto;
    text-align: center;
  }
  
  .inicio-articulo .imagen-background .slider-typography .slider-main h1 {
    font-size: 32px;
    text-transform: uppercase;
  }
  
  .inicio-articulo .imagen-background .slider-typography .slider-main p {
    color: #626262;
    font-size: 14px;
    margin: 15px 0px 0px 0px;
    font-weight: 500;
  }

  .articulo {
    width: 100%;
    height: auto;
    color: white;
  }

  .articulo .container {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 30px 0px;
  }

  .articulo .container h2 {
    font-size: 20px;
    color: #1a1a1a;
    text-transform: uppercase;
  }

  .articulo .container h3 {
    font-size: 16px;
    color: #1a1a1a;
    text-transform: uppercase;
  }

  .articulo .container .firm {
    color: #626262;
    font-size: 12px;
    margin: 5px 0px 20px 0px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .articulo .container p {
    color: #626262;
    font-size: 14px;
    margin: 10px 0px;
    font-weight: 500;
  }

  .articulo .container p span {
    font-weight: bolder;
    color: #1a1a1a;
  }

  .articulo .container .firm .type {
    margin: 0px 5px 0px 10px;
    background-color: #333;
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
  }

  .articulo .container p a {
    color: #4fb286;
  }

  .articulo .container p a:hover {
    text-decoration: underline;
  }

  .articulo .container p iframe {
    width: 1220px;
    height: 685px;
    border: 0;
  }

  .articulo .container p img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  pre {
    display: block;
    font-family: monospace !important;
    white-space: pre;
    margin: 15px 0px;
    user-select: text !important;
  }

  code,
  pre {
    background: #1a1a1a;
    border-radius: 5px;
    font-family: monospace !important;
    user-select: text !important;
  }

  ::selection {
    background: gray;
  }

  pre {
    padding: 10px;
    overflow: auto;
    box-shadow: 0 0 3px #1a1a1a;
    font-family: monospace !important;
  }

  code,
  pre {
    font-family: monospace !important;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    font-size: 14px;
    tab-size: 4;
    hyphens: none;
    color: white !important;
  }

  code span:nth-child(1),
  code span:nth-child(4) {
    font-family: monospace !important;
    color: yellow !important;
  }

  code span:nth-child(2) {
    font-family: monospace !important;
    color: #999;
  }

  code a {
    font-family: monospace !important;
    color: white;
  }

  code a:hover {
    text-decoration: underline;
  }

  .articulo .container ul {
    width: 100%;
    margin: 0px 0px 15px 45px;
    padding-right: 50px;
  }

  .articulo .container ul li {
    color: #626262;
    font-size: 14px;
    margin: 5px 0px;
    font-weight: 500;
    list-style: circle;
  }

  .articulo .container ul li strong {
    font-weight: bolder;
  }

  .articulo .container ol {
    width: 100%;
    margin: 0px 0px 15px 45px;
    padding-right: 50px;
  }

  .articulo .container ol li {
    color: #626262;
    font-size: 14px;
    margin: 5px 0px;
    font-weight: 500;
    list-style: decimal;
  }

  .articulo .container ol li strong {
    font-weight: bolder;
  }

  .articulo .container .tabla {
    display: block;
    white-space: nowrap;
    overflow: auto hidden;
    max-width: 100%;
    margin-top: 30px;
    border-bottom: 1px solid #999;
  }

  .articulo .container .tabla table {
    font-variant-numeric: lining-nums tabular-nums;
    border-collapse: collapse;
    width: 100%;
  }

  .articulo .container .tabla table thead tr th {
    padding: 12px 24px;
    font-size: 16px;
    color: #999;
    text-align: left;
    text-transform: uppercase;
  }

  .articulo .container .tabla table tbody tr td {
    padding: 16px 24px;
    font-size: 16px;
    color: white;
    text-align: left;
  }

  .tabla table tbody tr:nth-child(odd) > td,
  .tabla table tbody tr:nth-child(odd) > th {
    background-color: #333;
  }

  .tabla table tbody tr:nth-child(even) > td,
  .tabla table tbody tr:nth-child(even) > th {
    background-color: #1a1a1a;
  }
}
