.inicio-proyecto {
  width: 100%;
  height: auto;
  color: white;
}

.inicio-proyecto .imagen-background {
  width: 100%;
  height: 40vh;
  background-color: #1a1a1a;
  position: relative;
}

.inicio-proyecto .imagen-background .slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(26, 26, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;
}

.inicio-proyecto .imagen-background .slider-typography .slider-main {
  width: 75%;
  height: auto;
  margin: auto;
  text-align: center;
}

.inicio-proyecto .imagen-background .slider-typography .slider-main h1 {
  font-size: 32px;
  text-transform: uppercase;
  color: white;
}

.inicio-proyecto .imagen-background .slider-typography .slider-main p a {
  color: #626262;
  font-weight: bolder;
}

.inicio-proyecto
  .imagen-background
  .slider-typography
  .slider-main
  p
  a:hover {
  text-decoration: underline;
}
.proyecto {
  width: 100%;
  height: auto;
  color: white;
}

.proyecto .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
}

.proyecto .container .firm {
  text-transform: uppercase;
  font-size: 12px;
}

.proyecto .container h2 {
  font-size: 20px;
}

.proyecto .container h3 {
  font-size: 16px;
}

.proyecto .container p a {
  color: #4fb286;
}

.proyecto .container p a:hover {
  text-decoration: underline;
}

.proyecto .container video {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

@media screen and (max-width: 599px) {
  .inicio-proyecto {
    width: 100%;
    height: auto;
    color: white;
  }
  
  .inicio-proyecto .imagen-background {
    width: 100%;
    height: 45vh;
    background-color: #1a1a1a;
    position: relative;
  }
  
  .inicio-proyecto .imagen-background .slider-typography {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(26, 26, 26, 0.6);
  }
  
  .inicio-proyecto .imagen-background .slider-typography .slider-main {
    width: 90%;
    height: auto;
    margin: 15vh auto;
    text-align: center;
  }
  
  .inicio-proyecto .imagen-background .slider-typography .slider-main h1 {
    font-size: 32px;
    text-transform: uppercase;
  }
  
  .inicio-proyecto .imagen-background .slider-typography .slider-main p {
    color: #626262;
    font-size: 14px;
    margin: 15px 0px 0px 0px;
    font-weight: 500;
  }
  
  .inicio-proyecto .imagen-background .slider-typography .slider-main p a {
    color: #626262;
    font-weight: bolder;
  }
  
  .inicio-proyecto
    .imagen-background
    .slider-typography
    .slider-main
    p
    a:hover {
    text-decoration: underline;
  }

  .proyecto {
    width: 100%;
    height: auto;
    color: white;
  }

  .proyecto .container {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 30px 0px;
  }

  .proyecto .container h2 {
    font-size: 20px;
    color: #1a1a1a;
    text-transform: uppercase;
  }

  .proyecto .container h3 {
    font-size: 16px;
    color: #1a1a1a;
    text-transform: uppercase;
  }

  .proyecto .container .firm {
    color: #626262;
    font-size: 12px;
    margin: 5px 0px 20px 0px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .proyecto .container p {
    color: #626262;
    font-size: 14px;
    margin: 10px 0px;
    font-weight: 500;
  }

  .proyecto .container p a {
    color: #4fb286;
    word-wrap: break-word;
  }

  .proyecto .container p a:hover {
    text-decoration: underline;
  }

  .proyecto .container p video {
    max-width: 100%;
    height: auto;
  }
}
