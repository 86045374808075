.container-hablemos {
  width: 35px;
  height: 35px;
  position: fixed;
  bottom: 62.5px;
  right: 50px;
  border-radius: 100%;
  color: white;
  font-size: 25px;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
}

.container-hablemos.activar-mensajeria {
  transform: scale(1.5);
  font-size: 16px;
  line-height: 36px;
}

.container-hablemos.activar-mensajeria::before,
.container-hablemos.activar-mensajeria::after {
  opacity: 1;
  animation: none;
}

.container-hablemos:before,
.container-hablemos:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  background-color: #4fb286;
  opacity: 0;
  border-radius: 100%;
  animation: ondas 1.7s linear infinite;
}

.container-hablemos:before {
  animation-delay: 1s;
}

.container-hablemos:after {
  animation-delay: 1.3s;
}

.container-hablemos i {
  position: relative;
  z-index: 2;
}

@keyframes ondas {
  0% {
    transform: scale(1);
  }
  15% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.container-mensajeria {
  position: fixed;
  right: 45px;
  bottom: 120px;
  background-color: white;
  border-radius: 5px;
  display: none;
}

.container-mensajeria.activar-mensajeria {
  display: block;
}

.container-mensajeria:before {
  position: absolute;
  bottom: -7.5px;
  right: 15px;
  left: auto;
  display: inline-block !important;
  border-right: 8px solid transparent;
  border-top: 8px solid #ffffff;
  border-left: 8px solid transparent;
  content: "";
}

.container-mensajeria ul {
  width: 100%;
  height: 100%;
  padding: 5px 0px;
}

.container-mensajeria ul li {
  width: 100%;
  height: auto;
}

.container-mensajeria ul li a {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0px 15px;
  align-items: center;
}

.container-mensajeria ul li a .icon {
  margin: 0px 10px 0px 0px;
  background-color: #4fb286;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: white;
}

.container-mensajeria ul li a:hover {
  background-color: #e6e6e6;
}

.container-mensajeria ul li a .text {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 500;
}

.container-mensajeria ul li a:hover .text {
  color: #4fb286;
}

.container-fijo {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px 0px 0px 5px;
  position: fixed;
  bottom: 120px;
  right: 0px;
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transform: translateX(40px);
}

.container-fijo.mostrar{
  transform: translateX(0);
}
