footer {
  width: 100%;
  height: auto;
  background-color: #f1f1f1;
}

footer .footer-main {
  width: 90%;
  height: 100%;
  padding: 30px 0px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

footer .footer-main h5 {
  color: #1a1a1a;
  font-weight: bolder;
  padding: 0px 0px 15px 0px;
  text-transform: uppercase;
  font-size: 14px;
}

footer .footer-main .marca-container {
  width: 45%;
}

footer .footer-main .marca-container p {
  color: #626262;
  padding: 0px 40px 10px 0px;
  font-size: 12px;
  font-weight: 600;
  text-align: justify;
}

footer .footer-main .menu-container {
  width: 20%;
}

footer .footer-main .menu-container ul {
  width: 100%;
  height: 100%;
}

footer .footer-main .menu-container ul li {
  margin: 0px 0px 5px 0px;
}

footer .footer-main .menu-container ul li a {
  color: #626262;
  font-size: 12px;
  font-weight: 600;
}

footer .footer-main .menu-container ul li a:hover,
footer .footer-main .menu-container ul li a.active {
  color: #1a1a1a;
}

footer .footer-main .contacto-container {
  width: 35%;
}

footer .footer-main .contacto-container .contacto-basico {
  width: 100%;
  height: auto;
}

footer .footer-main .contacto-container .contacto-basico ul {
  width: 100%;
  height: 100%;
}

footer .footer-main .contacto-container .contacto-basico ul li {
  margin: 0px 0px 10px 0px;
}

footer .footer-main .contacto-container .contacto-basico ul li a,
footer .footer-main .contacto-container .contacto-basico ul li p {
  display: flex;
  align-items: center;
  color: #626262;
  font-size: 12px;
  font-weight: 600;
}

footer .footer-main .contacto-container .contacto-basico ul li a .icon,
footer .footer-main .contacto-container .contacto-basico ul li p .icon {
  color: #1a1a1a;
  font-weight: bolder;
  font-size: 15px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  text-align: center;
  line-height: 30px;
  margin: 0px 5px 0px 0px;
}

footer .footer-main .contacto-container .contacto-basico ul li a:hover,
footer .footer-main .contacto-container .contacto-basico ul li p:hover {
  color: #1a1a1a;
}

footer .footer-main .contacto-container .contacto-social {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

footer .footer-main .contacto-container .contacto-social ul {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 40px;
}

footer .footer-main .contacto-container .contacto-social ul li {
  margin: 0px 15px 0px 0px;
}

footer .footer-main .contacto-container .contacto-social ul li a {
  color: #1a1a1a;
  font-weight: bolder;
  font-size: 20px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  text-align: center;
  line-height: 30px;
}

footer .footer-main .contacto-container .contacto-social ul li a:hover {
  color: #4fb286;
}

footer .firma {
  width: 100%;
  height: 80px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0px 5%;
  align-items: center;
  background-color: #1a1a1a;
}

footer .firma .logo {
  font-size: 14px;
  color: #626262;
  font-weight: 600;
  display: block;
}

footer .firma p {
  font-size: 9px;
  color: #626262;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
}

footer .firma p .icon {
  display: inline-block;
  margin: 0px 5px;
  color: #4fb286;
}

@media screen and (max-width: 599px) {
  footer {
    width: 100%;
    height: auto;
    background-color: #f7f7f7;
  }

  footer .footer-main {
    width: 90%;
    height: 100%;
    padding: 30px 0px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  footer .footer-main h5 {
    color: #1a1a1a;
    font-weight: bolder;
    padding: 0px 0px 15px 0px;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
  }

  footer .footer-main .marca-container {
    width: 100%;
  }

  footer .footer-main .marca-container p {
    color: #626262;
    padding: 0px 0px 10px 0px;
    font-size: 12px;
    font-weight: 600;
    text-align: justify;
  }

  footer .footer-main .menu-container {
    width: 100%;
  }

  footer .footer-main .menu-container ul {
    width: 100%;
    height: 100%;
  }

  footer .footer-main .menu-container ul li {
    margin: 0px 0px 5px 0px;
  }

  footer .footer-main .menu-container ul li a {
    color: #626262;
    font-size: 12px;
    font-weight: 600;
  }

  footer .footer-main .menu-container ul li a:hover,
  footer .footer-main .menu-container ul li a.active {
    color: #1a1a1a;
  }

  footer .footer-main .contacto-container {
    width: 100%;
  }

  footer .footer-main .contacto-container .contacto-basico {
    width: 100%;
    height: auto;
  }

  footer .footer-main .contacto-container .contacto-basico ul {
    width: 100%;
    height: 100%;
  }

  footer .footer-main .contacto-container .contacto-basico ul li {
    margin: 0px 0px 10px 0px;
  }

  footer .footer-main .contacto-container .contacto-basico ul li a {
    display: flex;
    align-items: center;
    color: #626262;
    font-size: 12px;
    font-weight: 600;
  }

  footer .footer-main .contacto-container .contacto-basico ul li a .icon {
    color: #1a1a1a;
    font-weight: bolder;
    font-size: 15px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    text-align: center;
    line-height: 30px;
    margin: 0px 5px 0px 0px;
  }

  footer .footer-main .contacto-container .contacto-basico ul li a:hover {
    color: #1a1a1a;
  }

  footer .footer-main .contacto-container .contacto-social {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  footer .footer-main .contacto-container .contacto-social ul {
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 0px;
    justify-content: center;
  }

  footer .footer-main .contacto-container .contacto-social ul li {
    margin: 0px 10px 0px 0px;
  }

  footer .footer-main .contacto-container .contacto-social ul li a {
    color: #1a1a1a;
    font-weight: bolder;
    font-size: 20px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    text-align: center;
    line-height: 30px;
  }

  footer .footer-main .contacto-container .contacto-social ul li a:hover {
    color: #4fb286;
  }

  footer .firma {
    width: 100%;
    height: 80px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    padding: 5px 5%;
  }

  footer .firma .logo {
    font-size: 14px;
    color: #626262;
    font-weight: 600;
    display: block;
  }

  footer .firma p {
    font-size: 7.5px;
    color: #626262;
    font-weight: 600;
    display: block;
    text-transform: uppercase;
    text-align: center;
  }

  footer .firma p .icon {
    display: inline-block;
    margin: 0px 5px;
    color: #4fb286;
  }
}
