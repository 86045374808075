.inicio-contactame {
  width: 100%;
  height: auto;
  color: white;
}

.inicio-contactame .imagen-background {
  width: 100%;
  height: 40vh;
  background-color: #1a1a1a;
  position: relative;
}

.inicio-contactame .imagen-background .slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(26, 26, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;
}

.inicio-contactame .imagen-background .slider-typography .slider-main {
  width: 75%;
  height: auto;
  margin: auto;
  text-align: center;
}

.inicio-contactame .imagen-background .slider-typography .slider-main h1 {
  font-size: 32px;
  text-transform: uppercase;
  color: white;
}

.inicio-contactame .imagen-background .slider-typography .slider-main p a {
  color: #626262;
  font-weight: bolder;
}

.inicio-contactame
  .imagen-background
  .slider-typography
  .slider-main
  p
  a:hover {
  text-decoration: underline;
}

.contactame {
  width: 100%;
  height: auto;
  color: white;
}

.contactame .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
  display: flex;
  justify-content: space-between;
  column-gap: 60px;
}

.contactame .container .container-left {
  width: 50%;
  height: 100%;
}

.contactame .container .container-right {
  width: 50%;
  height: 100%;
}

.contactame .container .container-left h2 {
  font-size: 20px;
}

.contactame .container .container-left p {
  text-align: justify;
}

.contactame .container .container-left p a {
  color: #626262;
  display: inline-block;
  font-weight: bolder;
}

.contactame .container .container-left p a:hover{
  text-decoration: underline;
}

.contactame .container .container-left .button {
  padding: 10px 30px;
  color: white;
  background-color: #1a1a1a;
  border: 2px solid #1a1a1a;
  border-radius: 50px;
  width: max-content;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 12px;
  display: inline-block;
  margin: 10px 0px;
}

.contactame .container .container-left .button:hover {
  background-color: transparent;
  border: 2px solid #1a1a1a;
  color: #1a1a1a;
}

.contactame .container .container-right .formulario-container {
  width: 100%;
  height: 100%;
}

.formulario-container form input {
  width: 100%;
  padding: 10px;
  margin: 0px 0px 10px 0px;
  background-color: transparent;
  outline: none;
  border: 1px solid #626262;
  border-radius: 15px;
  display: block;
  color: #626262;
  font-size: 14px;
  font-weight: 600;
}

.formulario-container form input::placeholder,
.formulario-container form textarea::placeholder {
  color: #626262;
  font-weight: 500;
}

.formulario-container form textarea {
  resize: none;
  width: 100%;
  padding: 10px;
  margin: 0px 0px 10px 0px;
  padding: 10px 10px 60px 10px;
  background-color: transparent;
  outline: none;
  border: 1px solid #626262;
  border-radius: 15px;
  display: block;
  color: #626262;
  font-size: 14px;
  font-weight: 600;
}

.formulario-container form button {
  padding: 10px 20px;
  background-color: #1a1a1a;
  color: white;
  display: block;
  margin-top: 20px;
  font-weight: 600;
  outline: none;
  border-radius: 50px;
  border: 1px solid #1a1a1a;
  text-transform: uppercase;
}

.formulario-container form button.active,
.formulario-container form button:hover {
  background-color: transparent;
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
}

.formulario-container p {
  color: #626262;
  font-weight: 600;
  font-size: 14px;
}

.google-maps {
  width: 100%;
  height: auto;
}

.google-maps .container {
  width: 100%;
  height: auto;
}

.google-maps .container iframe {
  max-width: 100%;
  width: 100%;
  height: 450px;
  border: 0;
  display: block;
}

@media screen and (max-width: 599px) {
  .inicio-contactame {
    width: 100%;
    height: auto;
    color: white;
  }
  
  .inicio-contactame .imagen-background {
    width: 100%;
    height: 45vh;
    background-color: #1a1a1a;
    position: relative;
  }
  
  .inicio-contactame .imagen-background .slider-typography {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(26, 26, 26, 0.6);
  }
  
  .inicio-contactame .imagen-background .slider-typography .slider-main {
    width: 90%;
    height: auto;
    margin: 15vh auto;
    text-align: center;
  }
  
  .inicio-contactame .imagen-background .slider-typography .slider-main h1 {
    font-size: 32px;
    text-transform: uppercase;
  }
  
  .inicio-contactame .imagen-background .slider-typography .slider-main p {
    color: #626262;
    font-size: 14px;
    margin: 15px 0px 0px 0px;
    font-weight: 500;
  }

  .contactame {
    width: 100%;
    height: auto;
    color: white;
  }

  .contactame .container {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 60px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .contactame .container .container-left {
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  .contactame .container .container-right {
    width: 100%;
    height: 100%;
  }

  .contactame .container .container-left p a {
    color: #626262;
    display: inline-block;
    font-weight: bolder;
  }

  .contactame .container .container-right .formulario-container {
    width: 100%;
    height: 100%;
    padding: 30px 0px 0px 0px;
  }

  .google-maps .container iframe {
    max-width: 100%;
    width: 100%;
    height: 345px;
    border: 0;
    display: block;
  }
}
