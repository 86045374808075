.inicio-servicio {
  width: 100%;
  height: auto;
  color: white;
}

.inicio-servicio .imagen-background {
  width: 100%;
  height: 40vh;
  background-color: #1a1a1a;
  position: relative;
}

.inicio-servicio .imagen-background .slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(26, 26, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;
}

.inicio-servicio .imagen-background .slider-typography .slider-main {
  width: 75%;
  height: auto;
  margin: auto;
  text-align: center;
}

.inicio-servicio .imagen-background .slider-typography .slider-main h1 {
  font-size: 32px;
  text-transform: uppercase;
  color: white;
}

.inicio-servicio .imagen-background .slider-typography .slider-main a {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 5px 15px;
  border-radius: 50px;
  width: max-content;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 12px;
  display: block;
  margin: 15px auto;
}

.inicio-servicio .imagen-background .slider-typography .slider-main a:hover {
  color: #1a1a1a;
  background-color: white;
}

.servicio {
  width: 100%;
  height: auto;
}

.servicio .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
}

.servicio .container h2 {
  font-size: 20px;
  text-align: center;
}

.servicio .container h3 {
  font-size: 16px;
  text-align: center;
  margin: 45px 0px 0px 0px;
}

.servicio .container .firm {
  color: #626262;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.servicio .container p a {
  display: block;
  padding: 10px 30px;
  color: white;
  background-color: #1a1a1a;
  border: 2px solid #1a1a1a;
  border-radius: 50px;
  width: max-content;
  text-transform: uppercase;
  margin: 45px auto 0px;
  font-weight: bolder;
}

.servicio .container p a:hover {
  background-color: transparent;
  color: #1a1a1a;
}

.servicio .container p {
  text-align: justify;
}

.servicio .container ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 30px;
}

.servicio .container ul li {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.servicio .container ul li i {
  font-size: 60px;
  color: #4fb286;
}

.servicio .container ul li .text {
  color: #626262;
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0px;
}

@media screen and (max-width: 599px) {
  .inicio-servicio {
    width: 100%;
    height: auto;
    color: white;
  }

  .inicio-servicio .imagen-background {
    width: 100%;
    height: 45vh;
    background-color: #1a1a1a;
    position: relative;
  }

  .inicio-servicio .imagen-background .slider-typography {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(26, 26, 26, 0.6);
  }

  .inicio-servicio .imagen-background .slider-typography .slider-main {
    width: 90%;
    height: auto;
    margin: 15vh auto;
    text-align: center;
  }

  .inicio-servicio .imagen-background .slider-typography .slider-main h1 {
    font-size: 32px;
    text-transform: uppercase;
  }

  .inicio-servicio .imagen-background .slider-typography .slider-main p {
    color: #626262;
    font-size: 14px;
    margin: 15px 0px 0px 0px;
    font-weight: 500;
  }

  .servicio {
    width: 100%;
    height: auto;
    color: white;
  }
  
  .servicio .container {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 60px 0px;
  }
  
  .servicio .container h2 {
    font-size: 20px;
    color: #1a1a1a;
    text-transform: uppercase;
    text-align: center;
  }
  
  .servicio .container h3 {
    font-size: 16px;
    color: #1a1a1a;
    text-transform: uppercase;
    text-align: center;
    margin: 45px 0px 0px 0px;
  }
  
  .servicio .container .firm {
    color: #626262;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  
  .servicio .container p a {
    background-color: #1a1a1a;
    border: 2px solid #1a1a1a;
    color: white;
    width: max-content;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: bolder;
    display: block;
    margin: 30px auto;
    text-transform: uppercase;
  }
  
  .servicio .container p a:hover {
    background-color: transparent;
    color: #1a1a1a;
  }
  
  .servicio .container p {
    color: #626262;
    font-size: 14px;
    margin: 10px 0px;
    font-weight: 500;
    text-align: justify;
  }
  
  .servicio .container ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 30px 0px;
  }
  
  .servicio .container ul li {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 15px 0px;
  }
  
  .servicio .container ul li i {
    font-size: 60px;
    color: #4fb286;
  }
  
  .servicio .container ul li .text {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0px;
  }
}
