.inicio {
  width: 100%;
  height: auto;
}

.inicio .imagen-background {
  width: 100%;
  height: 100vh;
  background: center / cover no-repeat url(../../assets/images/marca/fondo.png);
  position: relative;
}

.inicio .imagen-background .slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(26, 26, 26, 0.93);
}

.inicio .imagen-background .slider-typography .slider-main {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inicio .imagen-background .slider-typography .slider-main h1 {
  display: block;
  color: white;
}

.inicio .imagen-background .slider-typography .slider-main h1 .headline {
  display: block;
  margin: auto;
  background-color: #4fb286;
  padding: 15px;
  font-size: 32px;
  width: max-content;
  border-radius: 50px;
}

.inicio .imagen-background .slider-typography .slider-main h1 .multiText {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  display: inline-block;
  margin: 30px 0px 0px 0px;
}

.container .button {
  margin-top: 45px;
}

.container .button a {
  display: block;
  padding: 10px 30px;
  color: white;
  background-color: #1a1a1a;
  border: 2px solid #1a1a1a;
  border-radius: 50px;
  width: max-content;
  text-transform: uppercase;
  margin: auto;
  font-weight: bolder;
}

.container .button a:hover {
  background-color: transparent;
  border: 2px solid #1a1a1a;
  color: #1a1a1a;
}

.inicio-sobremi {
  width: 100%;
  height: auto;
  text-align: center;
}

.inicio-sobremi .container {
  width: 85%;
  height: auto;
  margin: auto;
  padding: 60px 0px 30px 0px;
}

.inicio-sobremi .container .title {
  color: #626262;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bolder;
}

.inicio-sobremi .container h2 {
  margin: 15px 0px;
}

.inicio-sobremi .container ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  column-gap: 60px;
}

.inicio-sobremi .container ul li {
  width: max-content;
}

.inicio-sobremi .container ul li a {
  color: #4fb286;
}

.inicio-sobremi .container ul li a p {
  margin: 5px 0px;
  font-size: 12px;
}

.inicio-miservicios {
  width: 100%;
  height: auto;
  text-align: center;
}

.inicio-miservicios .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 30px 0px;
}

.inicio-miservicios .container p {
  margin: 15px auto;
  width: 60%;
}

.inicio-miservicios .container .list-services {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  gap: 20px;
}

.inicio-miservicios .container .list-services .service {
  width: 33.33%;
  height: auto;
  padding: 30px;
  border: 2px solid #cfcfcf;
  border-radius: 15px;
}

.inicio-miservicios .container .list-services .service:hover{
  border: 2px solid #4fb286;
}

.inicio-miservicios .container .list-services .service .icon {
  background-color: #4fb286;
  color: white;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  font-size: 45px;
  margin: auto;
  text-align: center;
  line-height: 100px;
}

.inicio-miservicios .container .list-services .service a {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin: 15px 0px;
}

.inicio-miservicios .container .list-services .service a:hover{
  color: #626262;
}

.inicio-miservicios .container .list-services .service p {
  width: 100%;
  font-size: 12px;
  text-align: justify;
}

.inicio-miportafolio {
  width: 100%;
  height: auto;
}

.inicio-miportafolio .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 30px 0px 60px 0px;
  text-align: center;
}

.inicio-miportafolio .container .list-proyects {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  gap: 20px;
}

.inicio-miportafolio .container .list-proyects .proyect {
  width: 33.33%;
  height: auto;
}

.inicio-miportafolio .container .list-proyects .proyect .cover {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 15px;
}

.inicio-miportafolio .container .list-proyects .proyect .cover video {
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  object-position: center;
  transition: all 0.4s ease;
  border: 1px solid #f9f9f9;
}

.inicio-miportafolio .container .list-proyects .proyect:hover .cover video {
  transform: scale(1.3);
}

.inicio-miportafolio .container .list-proyects .proyect .info {
  width: 100%;
  height: auto;
  margin: 15px 0px;
}

.inicio-miportafolio .container .list-proyects .proyect .info .date {
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: #626262;
  text-transform: uppercase;
}

.inicio-miportafolio .container .list-proyects .proyect .info a {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin: 10px 0px;
}

.inicio-miportafolio .container .list-proyects .proyect .info a:hover {
  color: #626262;
}

.inicio-miportafolio .container .list-proyects .proyect .info p {
  font-size: 12px;
  margin: 0px;
  text-align: justify;
}

.metodologia {
  width: 90%;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.metodologia .card {
  width: 25%;
  height: auto;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.metodologia .card .top {
  width: 100%;
  height: 150px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 300ms;
  position: relative;
  top: 0;
}

.metodologia .card .top .number {
  display: block;
  color: #4fb286;
  font-size: 48px;
  font-weight: bolder;
}

.metodologia .card .top .title {
  display: block;
  font-size: 16px;
  color: #1a1a1a;
  text-transform: uppercase;
  font-weight: bolder;
}

.metodologia .card .top .line {
  background-color: #cfcfcf;
  width: 15%;
  height: 3px;
  margin: 10px auto;
  display: block;
}

.metodologia .card .bottom {
  width: 100%;
  height: 150px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 300ms;
  position: absolute;
  bottom: -100%;
}

.metodologia .card .bottom .icon {
  color: #4fb286;
  font-size: 32px;
}

.metodologia .card .bottom p {
  color: #626262;
  font-size: 12px;
  margin: 15px 0px;
  font-weight: 500;
}

.metodologia .card:hover .bottom {
  bottom: 0px;
}

.metodologia .card:hover .top {
  top: -150px;
}

.inicio-canales{
  width: 100%;
  height: auto;
  background-color: #1a1a1a;
}

.inicio-canales .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
  text-align: center;
}

.inicio-canales .container img {
  display: block;
  margin: 0px auto 15px;
  border-radius: 100%;
  border: 3px solid white;
  object-fit: cover;
  object-position: center;
  width: 140px;
  height: 140px;
}

.inicio-canales .container h3 {
  color: white;
}

.inicio-canales .container .list-multimedia {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px 0px 0px;
}

.inicio-canales .container .list-multimedia a {
  display: block;
  margin: 0px 15px 0px 15px;
  width: 180px;
  height: 45px;
  background-color: white;
  line-height: 45px;
  color: #1a1a1a;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 14px;
  border-radius: 50px;
}

.inicio-canales .container .list-multimedia a:nth-child(1) .icon {
  color: #FF0000;
  margin: 0px 5px;
}

.inicio-canales .container .list-multimedia a:nth-child(1):hover {
  background-color: #FF0000;
  color: white;
}

.inicio-canales .container .list-multimedia a:nth-child(1):hover .icon {
  color: white;
}

.inicio-canales .container .list-multimedia a:nth-child(2) .icon {
  color: #1DB954;
  margin: 0px 5px;
}

.inicio-canales .container .list-multimedia a:nth-child(2):hover {
  background-color: #1DB954;
  color: white;
}

.inicio-canales .container .list-multimedia a:nth-child(2):hover .icon {
  color: white;
}

.inicio-miblog {
  width: 100%;
  height: auto;
}

.inicio-miblog .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px 30px 0px;
  text-align: center;
}

.inicio-miblog .container .list-posts {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  gap: 20px;
}

.inicio-miblog .container .list-posts .post {
  width: 33.33%;
  height: auto;
}

.inicio-miblog .container .list-posts .post .cover {
  width: 100%;
  height: 270px;
  overflow: hidden;
  border-radius: 15px;
}

.inicio-miblog .container .list-posts .post .cover img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top;
  transition: all 0.4s ease;
}

.inicio-miblog .container .list-posts .post:hover .cover img {
  transform: scale(1.3);
}

.inicio-miblog .container .list-posts .post .info {
  width: 100%;
  height: auto;
  margin: 15px 0px;
}

.inicio-miblog .container .list-posts .post .info .date {
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: #626262;
  text-transform: uppercase;
}

.inicio-miblog .container .list-posts .post .info a {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin: 10px 0px;
}

.inicio-miblog .container .list-posts .post .info a:hover {
  color: #626262;
}

.inicio-miblog .container .list-posts .post .info p {
  font-size: 12px;
  margin: 0px;
  text-align: justify;
}

.inicio-empresas {
  width: 100%;
  height: auto;
}

.inicio-empresas .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 30px 0px 60px 0px;
  text-align: center;
}

.inicio-empresas .container .cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  gap: 20px;
}

.inicio-empresas .container .cards .card {
  width: 100%;
  height: 100%;
}

.inicio-empresas .container .cards .card img {
  max-width: 100%;
  height: 60px;
  object-fit: contain;
  object-position: center;
  display: block;
  margin: auto;
  filter: brightness(1);
  mix-blend-mode: multiply;
}

@media screen and (max-width: 599px) {
  .inicio {
    width: 100%;
    height: auto;
    color: white;
  }
  
  .inicio .imagen-background {
    width: 100%;
    height: 100vh;
    background: center / cover no-repeat url(../../assets/images/marca/fondo.png);
    position: relative;
  }
  
  .inicio .imagen-background .slider-typography {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(26, 26, 26, 0.9);
  }
  
  .inicio .imagen-background .slider-typography .slider-main {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inicio .imagen-background .slider-typography .slider-main h1 {
    display: block;
  }
  
  .inicio .imagen-background .slider-typography .slider-main h1 .headline {
    display: block;
    margin: auto;
    background-color: #4fb286;
    padding: 15px;
    font-size: 32px;
    width: max-content;
  }
  
  .inicio .imagen-background .slider-typography .slider-main h1 .multiText {
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 2px;
    display: inline-block;
    margin: 30px 0px 0px 0px;
  }
  
  .inicio-sobremi {
    width: 100%;
    height: auto;
  }
  
  .inicio-sobremi .container {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 60px 0px 30px 0px;
    text-align: center;
  }
  
  .inicio-sobremi .container ul {
    column-gap: 45px;
  }
  
  .inicio-miservicios {
    width: 100%;
    height: auto;
    text-align: center;
  }
  
  .inicio-miservicios .container {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 30px 0px;
  }
  
  .inicio-miservicios .container p {
    width: 100%;
  }
  
  .inicio-miservicios .container .list-services {
    flex-wrap: wrap;
  }
  
  .inicio-miservicios .container .list-services .service {
    width: 100%;
  }
  
  .inicio-miportafolio {
    width: 100%;
    height: auto;
  }
 
  .inicio-miportafolio .container .list-proyects {
    flex-wrap: wrap;
  }
  
  .inicio-miportafolio .container .list-proyects .proyect {
    width: 100%;
    height: auto;
  }

  .inicio-miblog {
    width: 100%;
    height: auto;
  }
 
  .inicio-miblog .container .list-posts {
    flex-wrap: wrap;
  }
  
  .inicio-miblog .container .list-posts .post {
    width: 100%;
    height: auto;
  }

  .metodologia {
    flex-wrap: wrap;
  }
  
  .metodologia .card {
    width: 100%;
  }
  
  .inicio-empresas .container .cards {
    flex-wrap: wrap;
    gap: 40px;
  }
}