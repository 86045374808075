.sobremi-inicio {
  width: 100%;
  height: auto;
  color: white;
}

.sobremi-inicio .imagen-background {
  width: 100%;
  height: 100vh;
  background: center / cover no-repeat
    url(../../assets/images/marca/sobre-mi.jpg);
  position: relative;
}

.sobremi-inicio .imagen-background .slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.sobremi-inicio .imagen-background .slider-typography .slider-main {
  height: 70%;
  width: 90%;
  margin: 15% auto;
}

.sobremi-inicio
  .imagen-background
  .slider-typography
  .slider-main
  .second-title {
  display: block;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.sobremi-inicio .imagen-background .slider-typography .slider-main .title {
  display: block;
  margin: 10px 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bolder;
}

.sobremi-inicio .imagen-background .slider-typography .slider-main .pro {
  display: block;
  margin: 200px 0px 0px 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.sobremi-inicio .imagen-background .slider-typography .footer-banner {
  background-color: #1a1a1a;
  text-align: center;
  text-transform: uppercase;
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 10px;
  font-weight: 600;
}

.sobremi-inicio .imagen-background .slider-typography .footer-banner .icon {
  display: inline-block;
  margin: 0px 5px;
  color: #4fb286;
}

.sobre-mi {
  width: 100%;
  height: auto;
}

.sobre-mi .container {
  width: 75%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
  text-align: center;
}

.sobre-mi .container ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sobre-mi .container ul li {
  padding: 10px 20px;
  color: #4fb286;
}

.sobre-mi .container ul li p {
  font-size: 12px;
  margin: 5px 0px;
}

.habilidades {
  width: 100%;
  height: auto;
}

.habilidades .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px 30px 0px;
  text-align: center;
}

.habilidades .container .skills {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 45px;
  gap: 30px;
}

.habilidades .container .skills .skill {
  width: 10%;
  height: auto;
}

.habilidades .container .skills .skill .skill-top{
  width: 100%;
  height: auto;
}

.habilidades .container .skills .skill .skill-top .circle-img{
  width: 70px;
  height: 70px;
  background-color: white;
  border: 1px solid #e6e6e6;
  box-shadow: 1px 2px 3px 4px #e6e6e6;
  margin: auto;
  border-radius: 100%;
  padding: 17.5px;
  transition: all 0.5s ease-in-out;
}

.habilidades .container .skills .skill .skill-top .circle-img:hover{
  transform: scale(1.1);
}

.habilidades .container .skills .skill .skill-top .circle-img img {
  width: 35px;
  height: 35px;
  display: block;
  object-fit: contain;
  object-position: center;
  border-radius: 4px;
}

.habilidades .container .skills .skill p{
  text-align: center;
  margin: 10px 0px;
  font-weight: bolder;
  font-size: 12px;
}

@media screen and (max-width: 599px) {
  .sobremi-inicio {
    width: 100%;
    height: auto;
    color: white;
  }

  .sobremi-inicio .imagen-background {
    width: 100%;
    height: 50vh;
    background: center / cover no-repeat
      url(../../assets/images/marca/sobre-mi.jpg);
    position: relative;
  }

  .sobremi-inicio .imagen-background .slider-typography {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(26, 26, 26, 0.6);
  }

  .sobremi-inicio .imagen-background .slider-typography .slider-main {
    height: 50%;
    width: 90%;
    margin: 16vh auto;
  }

  .sobremi-inicio
    .imagen-background
    .slider-typography
    .slider-main
    .second-title {
    display: block;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }

  .sobremi-inicio .imagen-background .slider-typography .slider-main .title {
    display: block;
    margin: 10px 0px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bolder;
  }

  .sobremi-inicio .imagen-background .slider-typography .slider-main .pro {
    display: block;
    margin: 60px 0px 0px 0px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }

  .sobremi-inicio .imagen-background .slider-typography .footer-banner {
    background-color: #1a1a1a;
    text-align: center;
    text-transform: uppercase;
    padding: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 8px;
    font-weight: 600;
  }

  .sobre-mi .container {
    width: 90%;
  }
  
  .sobre-mi .container ul {
    flex-wrap: wrap;
  }
  
  .habilidades .container .skills {
    gap: 30px;
  }
  
  .habilidades .container .skills .skill {
    width: 15%;
    height: auto;
  }
}
