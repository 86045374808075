.inicio-blog {
  width: 100%;
  height: auto;
  color: white;
}

.inicio-blog .imagen-background {
  width: 100%;
  height: 40vh;
  background-color: #1a1a1a;
  position: relative;
}

.inicio-blog .imagen-background .slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(26, 26, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;
}

.inicio-blog .imagen-background .slider-typography .slider-main {
  width: 75%;
  height: auto;
  margin: auto;
  text-align: center;
}

.inicio-blog .imagen-background .slider-typography .slider-main h1 {
  font-size: 32px;
  text-transform: uppercase;
  color: white;
}

.inicio-blog .imagen-background .slider-typography .slider-main p a {
  color: #626262;
  font-weight: bolder;
}

.inicio-blog
  .imagen-background
  .slider-typography
  .slider-main
  p
  a:hover {
  text-decoration: underline;
}

.blog {
  width: 100%;
  height: auto;
}

.blog .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
  text-align: center;
}

.blog .container .list-posts {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.blog .container .list-posts .post {
  width: 33.33%;
  height: auto;
}

.blog .container .list-posts .post .cover {
  width: 100%;
  height: 270px;
  overflow: hidden;
  border-radius: 15px;
}

.blog .container .list-posts .post .cover img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top;
  transition: all 0.4s ease;
}

.blog .container .list-posts .post:hover .cover img {
  transform: scale(1.3);
}

.blog .container .list-posts .post .info {
  width: 100%;
  height: auto;
  margin: 15px 0px;
}

.blog .container .list-posts .post .info .date {
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: #626262;
  text-transform: uppercase;
}

.blog .container .list-posts .post .info a {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  margin: 10px 0px;
}

.blog .container .list-posts .post .info .button{
  text-align: left;
  background-color: transparent;
  border: 2px solid #1a1a1a;
  color: #1a1a1a;
  padding: 5px 15px;
  border-radius: 50px;
  width: max-content;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 10px;
  margin-top: 15px;
}

.blog .container .list-posts .post .info .button:hover{
  color: white;
  background-color: #1a1a1a;
  border: 2px solid #1a1a1a;
}

.blog .container .list-posts .post .info a:hover {
  color: #626262;
}

.blog .container .list-posts .post .info p {
  font-size: 12px;
  margin: 0px;
  text-align: justify;
}

@media screen and (max-width: 599px) {
 .inicio-blog {
    width: 100%;
    height: auto;
    color: white;
  }
  
 .inicio-blog .imagen-background {
    width: 100%;
    height: 45vh;
    background-color: #1a1a1a;
    position: relative;
  }
  
 .inicio-blog .imagen-background .slider-typography {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(26, 26, 26, 0.6);
  }
  
 .inicio-blog .imagen-background .slider-typography .slider-main {
    width: 90%;
    height: auto;
    margin: 15vh auto;
    text-align: center;
  }
  
 .inicio-blog .imagen-background .slider-typography .slider-main h1 {
    font-size: 32px;
    text-transform: uppercase;
  }
  
 .inicio-blog .imagen-background .slider-typography .slider-main p {
    color: #626262;
    font-size: 14px;
    margin: 15px 0px 0px 0px;
    font-weight: 500;
  }
  
 .inicio-blog .imagen-background .slider-typography .slider-main p a {
    color: #626262;
    font-weight: bolder;
  }
  
 .inicio-blog
    .imagen-background
    .slider-typography
    .slider-main
    p
    a:hover {
    text-decoration: underline;
  }

  .blog {
    width: 100%;
    height: auto;
  }
 
  .blog .container .list-posts {
    flex-wrap: wrap;
  }
  
  .blog .container .list-posts .post {
    width: 100%;
    height: auto;
  }

}