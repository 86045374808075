.inicio-servicios {
  width: 100%;
  height: auto;
  color: white;
}

.inicio-servicios .imagen-background {
  width: 100%;
  height: 40vh;
  background-color: #1a1a1a;
  position: relative;
}

.inicio-servicios .imagen-background .slider-typography {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(26, 26, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;
}

.inicio-servicios .imagen-background .slider-typography .slider-main {
  width: 75%;
  height: auto;
  margin: auto;
  text-align: center;
}

.inicio-servicios .imagen-background .slider-typography .slider-main h1 {
  font-size: 32px;
  text-transform: uppercase;
  color: white;
}

.inicio-servicios .imagen-background .slider-typography .slider-main p a {
  color: #626262;
  font-weight: bolder;
}

.inicio-servicios
  .imagen-background
  .slider-typography
  .slider-main
  p
  a:hover {
  text-decoration: underline;
}

.services {
  width: 100%;
  height: auto;
}

.services .container {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 60px 0px;
}

.services .container .list-services {
  width: 100%;
  height: auto;
}

.services .container .list-services .item-service {
  width: 100%;
  height: auto;
  display: flex;
  column-gap: 30px;
  padding: 30px;
  border: 2px solid #cfcfcf;
  border-radius: 15px;
  margin: 0px 0px 30px 0px;
}

.services .container .list-services .item-service:hover {
  border: 2px solid #4fb286;
}

.services .container .list-services .item-service .image {
  width: 10%;
  height: 100%;
  color: #4fb286;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.services .container .list-services .item-service .texto {
  width: 65%;
  height: 100%;
}

.services .container .list-services .item-service .texto .button{
  text-align: left;
  background-color: transparent;
  border: 2px solid #1a1a1a;
  color: #1a1a1a;
  padding: 5px 15px;
  border-radius: 50px;
  width: max-content;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 10px;
  margin-top: 15px;
}

.services .container .list-services .item-service .texto .button:hover{
  color: white;
  background-color: #1a1a1a;
  border: 2px solid #1a1a1a;
}

.services .container .list-services .item-service .texto a {
  font-size: 20px;
  color: #1a1a1a;
  text-transform: uppercase;
  font-weight: bolder;
}

.services .container .list-services .item-service .texto a:hover {
  color: #626262;
}

.services .container .list-services .item-service .texto p {
  font-size: 12px;
}

.services .container .list-services .item-service .texto p a {
  display: block;
  background-color: transparent;
  border: 2px solid #1a1a1a;
  color: #1a1a1a;
  width: max-content;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: bolder;
  margin-top: 20px;
  text-transform: uppercase;
}

.services .container .list-services .item-service .texto p a:hover {
  background-color: #1a1a1a;
  color: white;
}

.services .container .list-services .item-service .lista {
  width: 25%;
  height: 100%;
}

.services .container .list-services .item-service .lista h3 {
  font-size: 16px;
  margin-left: -20px;
}

.services .container .list-services .item-service .lista ul {
  width: 100%;
}

.services .container .list-services .item-service .lista ul li {
  color: #626262;
  font-size: 12px;
  margin: 10px 0px;
  font-weight: 500;
  list-style: circle;
}

@media screen and (max-width: 599px) {
  .inicio-services {
    width: 100%;
    height: auto;
    color: white;
  }

  .inicio-services .imagen-background {
    width: 100%;
    height: 45vh;
    background-color: #1a1a1a;
    position: relative;
  }

  .inicio-services .imagen-background .slider-typography {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(26, 26, 26, 0.6);
  }

  .inicio-services .imagen-background .slider-typography .slider-main {
    width: 90%;
    height: auto;
    margin: 15vh auto;
    text-align: center;
  }

  .inicio-services .imagen-background .slider-typography .slider-main h1 {
    font-size: 32px;
    text-transform: uppercase;
  }

  .inicio-services .imagen-background .slider-typography .slider-main p {
    color: #626262;
    font-size: 14px;
    margin: 15px 0px 0px 0px;
    font-weight: 500;
  }

  .inicio-services .imagen-background .slider-typography .slider-main p a {
    color: #626262;
    font-weight: bolder;
  }

  .inicio-services
    .imagen-background
    .slider-typography
    .slider-main
    p
    a:hover {
    text-decoration: underline;
  }

  .services {
    width: 100%;
    height: auto;
    color: white;
  }

  .services .container {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 60px 0px;
  }

  .services .container .list-services {
    width: 100%;
    height: auto;
  }

  .services .container .list-services .item-service {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
  }

  .services .container .list-services .item-service .image {
    width: 100%;
    height: 100%;
    font-size: 75px;
    padding: 20px 0px;
  }

  .services .container .list-services .item-service .texto {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .services .container .list-services .item-service .lista {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .services .container .list-services .item-service .lista h3 {
    margin-left: 0px;
  }

  .services .container .list-services .item-service .lista ul {
    width: 100%;
    padding: 0px 0px 0px 15px;
  }

}
