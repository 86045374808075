header {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  transition: all 300ms;
  background-color: transparent;
}

header .header-main {
  width: 90%;
  height: 100%;
  margin: auto;
}

header .header-main .nav-main {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .header-main .nav-main .logo-container {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}

header .header-main .nav-main .logo-container .logo {
  font-size: 16px;
  color: white;
  font-weight: 600;
}

header .header-main .nav-main .nav-container {
  width: auto;
  height: 100%;
}

header .header-main .nav-main .nav-container ul {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

header .header-main .nav-main .nav-container ul li {
  margin: 0px 0px 0px 30px;
}

header .header-main .nav-main .nav-container ul li a {
  color: white;
  font-size: 12px;
  font-weight: bolder;
  padding-bottom: 4px;
  text-transform: uppercase;
}

header .header-main .nav-main .nav-container ul li a:hover,
header .header-main .nav-main .nav-container ul li a.active {
  color: #4fb286;
}

header .header-main .nav-main .btn-menu {
  display: none;
}

.header-mov {
  background-color: white;
  border-bottom: 1px solid #f3f3f3;
  transform: translateY(-100%);
  animation: header 0.7s forwards;
}

@keyframes header {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0px);
  }
}

.header-mov .header-main .nav-main .logo-container .logo {
  color: #4fb286;
}

.header-mov .header-main .nav-main .nav-container ul li a {
  color: #1a1a1a;
}

.header-movil {
  display: none;
}

@media screen and (max-width: 599px) {
  header {
    max-width: 599px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;
    transition: all 300ms;
    background-color: transparent;
  }

  header .header-main {
    width: 90%;
    height: 100%;
    margin: auto;
  }
  
  header .header-main .nav-main {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  header .header-main .nav-main .logo-container {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
  }

  header .header-main .nav-main .logo-container .logo {
    font-size: 16px;
    color: white;
    font-weight: 600;
    display: block;
  }

  header .header-main .nav-main .nav-container {
    display: none;
  }

  header .header-main .nav-main .btn-menu {
    display: block;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 20px;
    color: white;
  }

  .header-mov .header-main .nav-main .btn-menu {
    color: #1a1a1a;
  }

  .header-movil {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;
    transform: translateX(-100%);
    transition: all 300ms ease;
  }

  .header-movil.active-header-movil {
    transform: translateX(0);
  }

  .header-movil .container {
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 26, 0.8);
    position: relative;
  }

  .header-movil .container .btn-menu {
    background-color: #1a1a1a;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 25px;
    top: 20px;
    color: white;
    font-weight: bolder;
    font-size: 20px;
    outline: none;
    border: none;
    border-radius: 5px;
  }

  .header-movil .container .nav-main {
    width: 75%;
    height: 100%;
    background-color: white;
  }

  .header-movil .container .nav-main .nav-container {
    width: 100%;
    height: auto;
  }

  .header-movil .container .nav-main .nav-container ul {
    width: 100%;
    height: 100%;
    padding: 15px 45px;
  }

  .header-movil .container .nav-main .nav-container ul li {
    display: block;
    margin: 30px 0px;
  }

  .header-movil .container .nav-main .nav-container ul li a {
    color: #1a1a1a;
    font-size: 12px;
    font-weight: bolder;
    padding-bottom: 4px;
    text-transform: uppercase;
  }

  .header-movil .container .nav-main .nav-container ul li a:hover,
  .header-movil .container .nav-main .nav-container ul li a.active {
    color: #4fb286;
  }

}
